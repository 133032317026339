<template>
  <vuestic-modal :isOpen="open" @cancel="close" :cancelShown="false" :okShown="false" large force>
    <span slot="title" class="text-primary font-weight-bold">AI Content Builder</span>
    <div class="ai-container">
      <div class="row">
        <div class="col-md-6">
          <SelectInput v-model="requestParams.tone" label="Select tone" name="tone" :options="toneOptions">
          </SelectInput>
        </div>
        <div class="col-md-6">
          <SelectInput v-model="requestParams.creativity" label="Creativity" name="tone" :options="creativityOptions">
          </SelectInput>
        </div>
        <div v-if="showQuestionInput" class="col-md-12">
          <div class="mt-4 mb-2">
            <div class="form-group mb-0">
              <label class="control-label">Ask AI to write a content for</label>
              <textarea v-model="question" class="form-control" name="key" rows="3"></textarea>
            </div>
            <small class="help pt-1">For best results provide maximum input</small>
          </div>
          <div class="mt-4 text-center">
            <button class="btn btn-primary" type="button" :disabled="!question || processing"
              @click="() => handleGenerateContent()">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto;" />
              <span v-else>Generate content</span>
            </button>
          </div>
        </div>
      </div>
      <div class="d-block">
        <div v-if="processing" class="text-center">
          <atom-spinner slot="loading" :animation-duration="1500" :size="55" color="#1a76d2"
            style="margin: auto; margin-top: 20px" />
        </div>
        <div v-else>
          <div v-if="showSubject && subject" class="mt-4 ai-generated" v-html="subject" contenteditable="false"></div>
          <div v-for="(item, index) in  suggestions" :key="index" :class="{ 'selected' : selectedIndex === index }" class="mt-4 ai-generated ai-generated-suggestion"
            v-html="item" contenteditable="false" @click="onSelectSuggestion(index)"></div>
        </div>
        <div v-if="hasSuggestion && !processing" class="mt-2 text-center">
          <button class="btn btn-success ml-2" type="button" :disabled="!selectContent" @click="done">
            Use This
          </button>
          <button class="btn btn-danger ml-2" type="button" @click="close">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </vuestic-modal>
</template>
  
<script>

export default {
  components: {},
  props: {
    open: {
      required: true,
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'sms',
    },
    value: {
      required: false,
      type: [String],
      default: '',
    },
    showSubject: {
      required: false,
      type: Boolean,
      default: false,
    },
    autoGenerate: {
      required: false,
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      toneOptions: [
        { label: 'Appreciative', value: 'Appreciative' },
        { label: 'Assertive', value: 'Assertive' },
        { label: 'Awestruck', value: 'Awestruck' },
        { label: 'Candid', value: 'Candid' },
        { label: 'Casual', value: 'Casual' },
        { label: 'Cautionary', value: 'Cautionary' },
        { label: 'Compassionate', value: 'Compassionate' },
        { label: 'Convincing', value: 'Convincing' },
        { label: 'Critical', value: 'Critical' },
        { label: 'Earnest', value: 'Earnest' },
        { label: 'Enthusiastic', value: 'Enthusiastic' },
        { label: 'Formal', value: 'Formal' },
        { label: 'Funny', value: 'Funny' },
        { label: 'Humble', value: 'Humble' },
        { label: 'Humorous', value: 'Humorous' },
        { label: 'Informative', value: 'Informative' },
        { label: 'Inspirational', value: 'Inspirational' },
        { label: 'Joyful', value: 'Joyful' },
        { label: 'Passionate', value: 'Passionate' },
        { label: 'Thoughtful', value: 'Thoughtful' },
        { label: 'Urgent', value: 'Urgent' },
        { label: 'Worried', value: 'Worried' }
      ],
      creativityOptions: [
        { label: 'Optimal', value: 'Optimal' },
        { label: 'None (more factual)', value: 'None' },
        { label: 'Low', value: 'Low' },
        { label: 'Medium', value: 'Medium' },
        { label: 'High', value: 'High' },
        { label: 'Max (less factual)', value: 'Max' }
      ],
      requestParams: {
        tone: { label: 'Casual', value: 'Casual' },
        creativity: { label: 'Optimal', value: 'Optimal' },
        question: '',
      },
      question: '',
      processing: false,
      subject: '',
      suggestions: [],
      selectedIndex: undefined,
      selectContent: '',
    }
  },

  computed: {
    hasSuggestion() {
      return this.suggestions && this.suggestions.length
    },

    showQuestionInput() {
      return this.type !== 'sms' || this.hasSuggestion || this.autoGenerate === false
    }
  },

  watch: {
    open: {
      handler: function (newValue) {
        if (newValue) {
          if (this.autoGenerate && this.type === 'sms' && !this.requestParams.question && !this.hasSuggestion) {
            this.handleGenerateContent(true)
          }
        }
      },
    },
    contact: {
      handler: function (newValue) {
        if (newValue) {
          if (this.autoGenerate && this.type === 'sms' && !this.requestParams.question) {
            this.initData()
          }
        }
      },
    }
  },

  methods: {
    initData() {
      this.question = ''
      this.subject = ''
      this.suggestions = []
      this.selectedIndex = undefined
      this.selectContent = ''
    },
    close() {
      this.$emit('closed')
    },

    done() {
      this.$emit('done', {
        subject: this.subject,
        content: this.selectContent
      })
    },

    onSelectSuggestion(index) {
      this.selectedIndex = index;
      this.selectContent = this.suggestions[index]
    },

    handleGenerateContent(auto = false) {
      if (this.question)
        this.requestParams.question = this.question
      let params = {
        ...this.requestParams,
        content_type: this.type,
        auto_genereate: auto,
        contact: this.contact ? this.contact.id : null,
      }
      this.processing = true
      this.$store
        .dispatch("business/generateAIContent", params)
        .then((res) => {
          if (res.success) {
            this.subject = res.data.subject
            this.suggestions = res.data.data
            if (this.type === 'email') {
              this.selectContent = this.suggestions[0]
            }
          }
          this.processing = false
        })
        .catch(() => {
          this.processing = false
        });
    },
  },
}
</script>
  
<style lang="scss">
.ai-container {
  display: block;

  textarea {
    border: 1px solid #e8e8e8 !important;
    margin-top: 10px;
  }

  .ai-generated {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #e8e8e8;
    padding: 5px 10px;
    outline: none;
    white-space: pre-line;
    max-height: 500px;
    width: 100%;
    overflow-y: auto;

    &.ai-generated-suggestion:hover,
    &.ai-generated-suggestion.selected {
      border: 2px solid $rb-blue;
    }
  }
}</style>
