<template>
  <VuesticPopover :popoverClass="customPopoverClass" :placement="placement" :auto-hide="autoHide" :open.sync="openValue"
    @onUpdateOpen="onUpdateOpen">
    <span slot="trigger" class="trigger-span">
      <slot name="trigger">
      </slot>
    </span>
    <span slot="body">
      <div class="row wrapper">
        <div v-if="!hideContactDetail" class="col-md-12 mb-4">
          <h6 class="text-center">Contact Details</h6>
          <div class="mergefield-wrapper">
            <div class="badge badge-primary mergefield-tags" @click="appendField('[first_name]')">
              First Name
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[last_name]')">
              Last Name
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[email]')">
              Email
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[phone_number]')">
              Phone Number
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[birthday]')">
              Birthday
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[anniversary]')">
              Anniversary
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[city]')">
              City
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[note]')">
              Note
            </div>

            <div v-for="customField in contactFields" class="badge badge-primary mergefield-tags" @click="appendField(`[custom_${customField.name }]`)"
              :key="customField.id">
              {{ customField.name }}
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <h6 class="text-center">My Details</h6>
          <div class="mergefield-wrapper">
            <div class="badge badge-primary mergefield-tags" @click="appendField('[my_first_name]', 'my')">
              My First Name
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[my_last_name]', 'my')">
              My Last Name
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[my_phone_number]', 'my')">
              My Phone Number
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[my_email]', 'my')">
              My Email
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[business_name]', 'my')">
              Business Name
            </div>
            <div class="badge badge-primary mergefield-tags" @click="appendField('[business_card]', 'my')">
              Business Card
            </div>
          </div>
        </div>
      </div>
    </span>
  </VuesticPopover>
</template>

<script>

import VuesticPopover from '../../../vuestic-theme/vuestic-components/vuestic-popover/VuesticPopover';

export default {
  name: "MergeFields",

  components: {
    VuesticPopover
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'auto'
    },
    popoverClass: {
      type: String,
      default: ''
    },
    autoHide: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    hideContactDetail: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      openValue: false,
    }
  },

  watch: {
    open: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.openValue = newValue;
        }
      },
      deep: true,
    }
  },

  created() {
    this.openValue = this.open
  },

  methods: {
    onUpdateOpen(val) {
      this.openValue = val;
    },
    appendField(value, type = 'contact') {
      this.openValue = false;
      this.$emit('onAdd', {
        value,
        type
      })
    },
  },

  computed: {
    customPopoverClass() {
      return 'custom-dropdown ' + this.popoverClass
    },

    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },
  }
};
</script>

<style lang='scss' scoped>
.trigger-span {
  cursor: pointer;
  color: inherit;
  font-weight: bold;
  display: inline-block;

  span.arrow-span {
    padding-left: 8px;
  }
}

.wrapper {
  min-width: 300px;
  padding: 10px 0;

  .tooltip-inner {
    background-color: $white;
    color: $darkest-gray;

    .popover-header,
    .popover-body {
      color: inherit;
      background-color: inherit;
    }

    .popover-body {
      text-align: left;
    }
  }
}
</style>

<style lang='scss'>
.custom-dropdown {
  top: -10px !important;
}
</style>
