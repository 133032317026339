<template>
<div class="action-block">
  <div class="common-action dark-gray" @click="showModal">
    <i :class="block.icon"></i> {{  block.name  }}
  </div>

  <vuestic-modal
    :isOpen="isShowModal"
    @cancel="isShowModal = false"
    :okShown="false"
    :cancelShown="false"
    :force="true"
    cancelClass="btn btn-danger"
  >
    <span slot="title" class="text-primary font-weight-bold">{{ block.description }}</span>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="sendMessageActionForm"
    >
      <form @submit.prevent="handleSubmit(save)" class="sendMessageActionForm">
        <div class="row">
          <div class="col-12 mb-4">
            <text-input v-model="block.name"></text-input>
          </div>
          <!-- <div class="col-md-12 mb-4">
            <label class="input-label">Email Account</label>
            <multiselect
              :show-labels="false" 
              v-model="eAccountSelected"
              :options="emailAccounts"
              :multiple="false"
              :preserve-search="true"
              placeholder="Select Item"
              label="email"
              track-by="email"
              @input="onSelectChange"
            >
            </multiselect>
          </div> -->
          <div class="col-md-12">
            <text-input label="Subject" v-model="block.options.title" name="title"></text-input>
          </div>
          <div class="col-md-12">
            <span><label class="input-label">Content </label></span>
            <validation-provider
              rules="required"
              v-slot="{ }"
              name="Message"
            >
              <TextEditorPicker
                v-model="block.options.content"
                :focused="true"
                :enabledEmoji="true"
                :enabledMenu="true"
                :enabledTemplate="true"
                :enable-ai="true"
                :showSendButton="false"
                ref="texteditor"
                :files="block.options.attachments"
                @done="handleAttachments"
              />
            </validation-provider>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-primary mr-4" :disabled="invalid || processing" @click="onClickTest">
              <span>Test Email</span>
            </button>
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>
  <vuestic-modal :isOpen="isOpenModalTest" @ok="handleTest" @cancel="isOpenModalTest = false" okText="Send"
    cancelText="Cancel" :closeOnOk="false" :processing="processing" okClass="btn btn-primary"
    :okDisabled="!testEmail">
    <span slot="title" class="text-primary font-weight-bold">Test SMS</span>
    <p>Please Input Email Addresss to Send Test Mail.</p>
    <text-input type="email" name="Email" placeholder="Enter email" v-model="testEmail" label="Email"/>
  </vuestic-modal>
</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SMSLength from '../../../../common/SMSLength'
import TextEditorPicker from "../../../../common/TextEditorPicker";

export default {
  components: { SMSLength, TextEditorPicker },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      processing: false,
      isShowModal: false,
      eAccountSelected: null,
      isOpenModalTest: false,
      testEmail: null,
    }
  },

  mounted() {
    this.$store
      .dispatch("businessIntegration/emailAccountAll")
    if (this.block.isNew) {
      this.block.isNew = false
      this.showModal()
    }
  },

  watch: {
    'emailAccounts': {
      handler: function(newValue) {
        if (this.block.options.emailAccount)
        {
          this.eAccountSelected = this.emailAccounts.find(item => item.email == this.block.options.emailAccount)
        }
      },
      deep: true,
    },
  },

  computed: {
    maxLength() {
      return 1600;
    },
    ...mapState('businessIntegration', {
      emailAccounts: "emailAccounts",
    }),
  },

  methods: {
    handleAttachments(images) {
      this.block.options.attachments = images
    },

    onSelectChange() {
      this.block.options.emailAccount = this.eAccountSelected ? this.eAccountSelected.email : null;
    },

    save() {
      // if (!this.block.options.emailAccount)
      // {
      //   Vue.$toast.open({
      //     message: 'Please select Google Account to send email.',
      //     type: 'error',
      //     duration: 3000,
      //   })
      //   return false;
      // }
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },

    onClickTest() {
      this.isOpenModalTest = true
    },

    handleTest() {
      this.processing = true
      const param = Vue.util.extend({}, this.block.options)
      param.email = this.testEmail

      this.$store.dispatch('automation/testEmail', param).then((res) => {
        this.isOpenModalTest = false
        this.processing = false
        this.testEmail = null
      })
      .catch(() => {
        this.processing = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>