<template>
  <div v-if="text" class="text-right sms-length-wrapper">
    <span><small v-b-tooltip.hover title="Number of credit our system will charge for this text">{{
      segmentCount
    }}</small> / <small v-b-tooltip.hover title="Number of total characters for this text">{{
  textCount
}}</small></span>
  </div>
</template>

<script>
const { SegmentedMessage } = require('sms-segments-calculator');

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    optText: {
      type: String,
      default: '',
    },

    hasImage: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    segmentCount() {
      if (this.hasImage)
        return 3
      let tmp = new SegmentedMessage(this.text + "\n\n" + this.optText)
      return tmp.segmentsCount
    },
    textCount() {
      return this.text.length + (this.optText ? this.optText.length: 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.sms-length-wrapper {
  small {
    cursor: pointer;
    text-align: center;
    display: inline-block;
    display: inline-block;
    width: 22px;

    &:hover {
      border-radius: 50px;
      font-weight: bold;
      text-decoration: underline;
      background-color: $rb-blue;
      color: #fff;
    }
  }
}
</style>
