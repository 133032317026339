<template>
  <div class="media-gallery-wrapper">
    <div class="d-flex justify-content-between pt-3 px-2 font-weight-bold text-primary">
      <span><span v-if="hasImageSelected">{{ selectedIds.length }} Selected</span></span>
      <button type="button" class="btn btn-sm btn-primary" :disabled="processing" @click="refresh">
        <span><i class="fa fa-refresh"></i> Refresh</span>
      </button>
    </div>
    <div class="d-flex justify-content-center" v-if="showLoading">
      <span class="atom-spinner-wrapper">
        <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)"/>
      </span>
    </div>
    <div v-else class="media-gallery-inner-wrapper pt-3">
      <div class="media-item" v-for="(item, key) in medias" :key="key" :class="{'selected': selectedIds.includes(item.id)}">
        <a href="javascript:;" @click="onClickMedia(item)">
          <span v-if="checkMediaType(item, ['image'])">
            <img class="preview" :src="item.path" />
            <span class="filename">{{ item.filename }}</span>
          </span>
          <span v-else class="file-wrapper"><i class="fa fa-file"></i> <b>{{ item.filename }}</b></span>
        </a>
        <i v-if="selectedIds.includes(item.id)" class="check-icon fa fa-check-circle"></i>
      </div>
      <div v-if="total > medias.length" class="text-center">
        <button type="button" class="btn btn-sm btn-primary" :disabled="processing" @click="loadMedia">
          <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
          <span v-else>Load More</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
  },

  data() {
    return {
      selectedIds: [],
    }
  },

  watch: {
    selectedIds: {
      handler (val) {
        this.$emit('imageSelected', this.selectedImages);
      },
      deep: true,
    },
  },

  computed: {
    showLoading() {
      return this.fetching && !this.medias.length
    },
    hasImageSelected() {
      return !!this.selectedIds.length
    },

    selectedImages() {
      return this.medias.filter(item => this.selectedIds.includes(item.id))
    },
    ...mapState('media', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      processing: 'processing',
      page: 'page',
      perPage: 'perPage',
      medias: 'data',
      total: 'total'
    })
  },

  mounted() {
    !this.didFetch && this.loadMedia()
  },

  methods: {
    refresh() {
      this.$store.dispatch('media/clearList')
      this.loadMedia()
    },

    reset() {
      this.selectedIds = []
    },
    
    loadMedia() {
      const param = {
        page: this.page + 1,
        per_page: this.perPage,
      }
      this.$store.dispatch('media/fetch', param)
    },

    onClickMedia(item) {
      if (this.selectedIds.includes(item.id))
      {
        let index = this.selectedIds.findIndex(i => i === item.id)
        this.selectedIds.splice(index, 1)
      } else {
        this.selectedIds.push(item.id)
      }
    },

    checkMediaType(media, types) {
      let isValid = false;
      types.forEach((type) => {
        if (media.type && media.type.startsWith(`${type}/`)) {
          isValid = true;
        }
      });

      return isValid;
    },
  }
}
</script>

<style lang="scss">
.media-gallery-wrapper {
  max-height: 70vh;
  overflow-y: auto;
  .media-gallery-inner-wrapper{
    padding: 0px 10px;
  }
  .media-item {
    margin-bottom: 20px;
    margin-right: 25px;
    display: inline-block;
    position: relative;
    padding: 1px;
    border-radius: 2px;
    width: 130px;

    &.selected {
      outline: 2px dashed #1b76d2;
    }

    &:hover {
      outline: 2px dashed #1b76d29c;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .preview {
      width: 127px;
      height: 160px;
      object-fit: contain;
    }

    i {
      width: 50px;
      font-size: 30px;
      margin-right: 10px;
      text-align: center;
    }
    
    i.check-icon {
      font-size: 18px;
      color: #1b76d2;
      position: absolute;
      top: -15px;
      right: 91px;
      z-index: 10;
    }
    span.filename {
      display: block;
      word-break: break-all;
    }
  }
}
</style>
